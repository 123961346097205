export const stringUtils: any = {
  companyName: 'GoPIPA',
  companyWebSite: 'www.gopipa.com',
  productName: 'iPIPA',
  homeTitle: '- Empower Investors with AI Investment Agents',
  homeSubTitle: 'Simple, Flexible, Transparent',
  homeSubTitle2: 'Higher Return with Lower Risk',
  home: 'GoPIPA is an AI fintech company which provides “AI Investment Agent” solutions to investors with its revolutionary platform and innovative technologies in AI, data analytics, algorithmic trading, and cloud computing.',
  homeOurVisionTitle: 'Our Vision',
  homeOurVision: 'In recent years, advancements in AI, data analytics, algorithmic trading, and cloud computing have created the "perfect storm" for transforming the investment management industry. The new generation of “AI Investment Agent” is here to revolutionize how investors operate, reduce costs, improve efficiency, and enhance investment results. GoPIPA is leading this transformation through its innovative AI investment agent solution, iPIPA. In this new paradigm, investors should no longer waste time in analyzing tremendous amounts of data and suffer psychological pressure in making individual stock buy/sell decisions. This new way of investing can generate higher return with lower risk and is simple, flexible and transparent.',
  homeIPIPAServicesTitle: 'iPIPA Services',
  homeIPIPAServices:
    'Currently, iPIPA provides four services to its users:<br>' +
      '<ol><li><b>AQS:</b><ul><li>A dynamic marketplace offering fully automated trading strategies, such as Factor Trading (FTS), Trend Trading (TTS), and Minimum Volatility Strategies (MVS), etc.</li>' +
      '<li>Users can subscribe/unsubscribe, adjust investment amounts, and monitor performance with simple point-and-click functionality.</li></ul></li>' +
      '<li><b>AQM:</b><ul><li>A curated marketplace of automated model accounts tailored to specific investment objectives, such as outperforming the S&P 500 ETF with higher Sharpe/Sortino ratios and reduced volatility and drawdowns.</li>' +
      '<li>Users can subscribe/unsubscribe, adjust investment amounts, and monitor performance with simple point-and-click functionality.</li></ul></li>' +
      '<li><b>IDA:</b><ul><li>Comprehensive AI-driven analysis of fundamental, technical, and sentiment data for the overall stock market and individual securities.</li>' +
      '<li>Features include anomaly detection, pattern recognition, trading signals, and predictive insights for user-specific watchlists.</li></ul></li>' +
      '<li><b>Ask-iPIPA:</b><ul><li>Combines the advanced capabilities of ChatGPT with iPIPA’s proprietary data for insightful investment research tailored to your needs.</li></ul></li></ol>' +
      'When using iPIPA AQS & AQM services, users’ assets (cash and stocks) are always in their brokerage accounts which they have 100% control. Users can use the “Ask iPIPA” & IDA as investment research agents, use AQS as trading agents, and use AQM as portfolio management agents. Also, users can access GoPIPA User Forum for support and sharing ideas with GoPIPA team and community members, participate GoPIPA user events (e.g. user seminars, etc.), and receive GoPIPA weekly newsletter.',
  homeIDATitle: 'IDA (Intelligent Data Analysis)',
  homeIDA:
    'iPIPA IDA is designed for active traders and experienced investors who can understand various investment related data and trading signals. iPIPA IDA helps users to make intelligent investment decisions by providing AI based data analysis on fundamental data, technical data, and sentiment data on overall stock market, individual sector, and individual stock/ETF. It also provides pattern and abnormality detection, stock movement prediction, and trading signals for the stocks in users’ watch lists and broker’s accounts. You can find the link to the user tutorial video including how to use iPIPA IDA in the iPIPA support page.',
  homeAQSTitle: 'AQS (AI Quant Strategy)',
  homeAQS:
    'iPIPA AQS is designed for experienced investors who can understand the performance metrics (e.g. alpha, beta, sharpe, sotino, volatility, max drawdown, etc.) of quantitative investment strategies in order to decide what and when to subscribe and unsubscribe them. With iPIPA AQS, users can subscribe to various fully automated AI based investment strategies from iPIPA strategy marketplace based on strategy’s performance, market conditions, and their return expectation and risk tolerance. Users can monitor their investment results and start/stop their portfolios at their fingertips any time. Currently, iPIPA AQS strategy marketplace includes various investment strategies including Minimum Volatility Strategy (MVS), Factor Trading Strategy (FTS), Trend Trading Strategy (TTS), etc. These investment strategies have different investment objectives and AI quantitative trading algorithms with CAGR ranging from 10%+ to 40%+. Users can increase or decrease the investment amount allocated to each strategy at any time. You can find the link to the user tutorial video including how to use iPIPA AQS in the iPIPA support page.',
  homeAQMTitle: 'AQM (AI Quant Model Account)',
  homeAQM:
    'iPIPA AQM is designed for investors who don’t have the knowledge or time to select individual investment strategies based on market conditions. With AQM, users can subscribe to various AQM model accounts. When the positions in AQM model accounts are updated, user accounts will be updated automatically. Typically, the investment goal of AQM model accounts is to generate a higher return than S&P 500 ETF (e.g. SPY) with much lower volatility and max drawdown. One example is an entry level of AQM model account AQM-CE (ID:9) which starts running from 3/3/2022. So far (3/6/2024), it has generated a higher return than S&P 500 ETF (SPY) with much lower volatility and max drawdown (less than 1/4 of SPY’s volatility and max drawdown). Users can monitor their investment results and start/stop their subscription at their fingertips any time. Also, users can increase or decrease their investment amount at any time. You can find the link to the user tutorial video including how to use iPIPA AQM in the iPIPA support page.',
  homeDiffValue: 'iPIPA’s Differentiated Value',
  homeDiffValueTitle:
    'iPIPA carries the following unique characteristics and highly differentiated values to its users:',
  homeDiffValueSubTitle: 'Differentiated value:',
  homeDiffValueDesc:
    'iPIPA carries the following unique characteristics and highly differentiated values to its users:',
  homeDiffValueDesc1:
    '1. iPIPA is powered by an innovative AI online learning system based on a patented DHL/AutoDHL(Auto Deep Hybrid Learning) framework. It utilizes various technologies to form a highly quantitative and optimized system. This system is self-learning in nature and takes the full advantage of the massive amount of market, media, and economic data to classify and form predictive results and real-time trading signals.\n',
  homeDiffValueDesc11:
    'The AI quant capability enables iPIPA to achieve the best investment results for its users.',
  homeDiffValueDesc2:
    '2. iPIPA is a flexible and extensible investment platform that provides all types of investment strategies that match users’ risk and return expectations. Besides a family of well diversified ETF strategies, it also extends to various specialized strategies (e.g. factor protection strategy, trend protection strategy, etc.) for certain users; something that were once off-limit, accessible only by high-asset and privileged hedge fund participants.\n',
  homeDiffValueDesc22:
    'Users enjoy the flexibility of iPIPA given its all-around, full spectrum of investment strategy choices, unlike most of today’s one-size-fit-all, robo-advisory services or ETF based model marketplace.',
  homeDiffValueDesc3:
    '3. iPIPA is a secure and transparent Software-as-a-Service (SaaS) investment platform that operates by leveraging cloud computing and elastic resources. Users keep their assets in their own independent brokerage accounts while using iPIPA as an intelligent investment tool. Investment’s holdings are therefore safely guarded by users’ own brokerage accounts.\n',
  homeDiffValueDesc33:
    'Users are assured of the security and transparency with the best of both worlds, while using the brokerage accounts and iPIPA investment platform arrangement.',
  homeDiffValueDesc4:
    '4. iPIPA is an ecosystem which users (e.g. investment managers or qualified individual investors) can communicate with each other and with GoPIPA team by a community forum. Users can make better decisions while selecting strategies if they understand the strength and weakness of each investment strategy by communicating with other users and GoPIPA team. GoPIPA team can also get direct feedback from users.\n',
  homeDiffValueDesc44:
    'This end-to-end model eliminates unnecessary cost overhead and avoid any miscommunication between users and GoPIPA team.',
  homeAI: 'Artificial Intelligence',
  homeAlgorithmicTrading: 'Algorithmic Trading',
  homeDataAnalytics: 'Data Analytics',
  homeSaaS: 'Software as a Service with high reliability, availability, and serviceability (RAS)',
  homeDisclaimer: 'Important Disclaimer',
  homeDisclaimerDesc:
    'GoPIPA and its differentiated offerings, standalone and collectively do not provide personal investment or financial advice to individuals, or act as personal finance, legal, or institutional investment advisors, or individually advocate the purchase or sale of any security or investment or the use of any financial strategy. All investing, stock forecasts, and investment strategies include the risk of loss for some or all of your capital.',
  homeEmail:
    'If you are interested in getting more information, please send an email to info@gopipa.com.',
  homeReservedRight: 'All Rights Reserved.',
  companyOverviewTitle: 'Overview',
  companyOverview:
    'GoPIPA is an AI fintech company which provides “AI Investment Agent” solutions to investors with its revolutionary platform and innovative technologies in AI, data analytics, algorithmic trading, and cloud computing.',
  companyVisionTitle: 'Vision',
  companyVision:
    'In recent years, advancements in AI, data analytics, algorithmic trading, and cloud computing have created the "perfect storm" for transforming the investment management industry. The new generation of “AI Investment Agent” is here to revolutionize how investors operate, reduce costs, improve efficiency, and enhance investment results. GoPIPA is leading this transformation through its innovative AI investment agent solution, iPIPA. In this new paradigm, investors should no longer waste time in analyzing tremendous amounts of data and suffer psychological pressure in making individual stock buy/sell decisions. This new way of investing can generate higher return with lower risk and is simple, flexible and transparent.',
  companyTeamTitle: 'Team',
  companyTeam:
    'GoPIPA team includes a group of seasoned and passionate entrepreneurs who have extensive experience in AI, data analytics, cloud computing, algorithmic trading, and investment management. CEO & Founder Ray Kao has over 30 years of experience in information technology (AI, cloud computing, networking, security) and investment management (algorithmic trading, risk management), including 15+ years of CEO, and 15+ years of senior management and product development. Ray is also a seasoned entrepreneur with a good track record of developing innovative technologies into successful businesses. Ray has received many awards, including Red Herring’s US Top 10 Innovators Award, and patents in various areas. He has presented and served as a panel expert at numerous conferences. GoPIPA’s investors and advisors include founders and CEOs of wall street leading hedge funds and Silicon Valley high tech companies.',
  supportIBSetup: 'How to set up an IB account for trading by iPIPA?',
  supportFAQ: 'GoPIPA Frequently Asked Questions (FAQ)',
  supportAQMService: 'How to use iPIPA AQM Services',
  supportSummary: 'GoPIPA Company & Solution Summary',
  supportOverview: 'GoPIPA Company & Solution Overview',
  supportUserTutorial: 'GoPIPA User Tutorial',
  supportUserReward: 'GoPIPA Friends and Family Reward Program Sign-up Form',
  supportAqmSummary: 'GoPIPA AQM Overview',
  supportText:
    'If you have any questions, please send an email to support@gopipa.com or post them in user forum.',
  supportUserSeminar: 'GoPIPA User Seminar',
  supportSeminar20231021: 'GoPIPA User Seminar Oct 21, 2023',
  supportSeminar20240113: 'GoPIPA User Seminar Jan 13, 2024',
  supportSeminar20240518: 'GoPIPA User Seminar May 18, 2024',
  subPortfolioActionOnTitle: 'Strategy Emergency Stop Settings',
  subPortfolioActionOn:
    'Are you sure you do NOT want iPIPA to sell all the holdings in this portfolio when Strategy Emergency Stop occurs(SES)?',
  portfolioNoImageNote:
    'Please note that this portfolio is in the waiting period (max portfolio update period) before the initial trade',
  registerSuccess:
    'Account sign up success. Activation email has been sent. Please open the email and click on the link to enable the account.',
  forgetSuccess:
    'Password reset email has been sent. Please open the email and click on the link to enable the new password.',
  fieldRequired: 'This field is required',
  printedNameMsg: 'Printed name does not match your sign up name',
  noUserAssessmentMsg:
    'You have not completed the User Risk Assessment process yet. Please answer the following Risk Assessment Questionnaires to set your risk level before subscribing a strategy.',
  redoUserAssessmentMsg:
    'You can only subscribe to the strategies with the same risk level or below. You can also change your risk level by redoing the User Risk Assessment.',
  userActivate: 'User is not activated. Please check your email to active GOPIPA user account.',
  requiredMsg: 'This field is required',
  supportUserReferral: 'Alpha User Referral Form',
  myCurrentAqsPortfolio: 'My Current AQS',
  myStoppedAqsPortfolio: 'My Stopped AQS',
  myQqsPortfolio: 'My AQS',
  aqsStrategies: 'AQS Service',
  aqmStrategies: 'AQM Accounts',
  aqmModelAccounts: 'AQM Service',
  allAqsStrategies: 'All AQS Strategies',
  allAqmStrategies: 'All AQM Model Accounts',
  myAqmStrategies: 'My AQM',
  myAqm: 'My AQM Accounts',
  myAqmStoppedStrategies: 'My Stopped AQM Accounts',
  dashboard_AUM: 'Total Assets Under Management (AUM)',
  usefulLink: 'Useful Links for Users',
  latestStrategies: 'The Strategies Released Recently',
  weeklyNewsletter: 'Weekly Newsletter',
  chart: 'iPIPA IDA: S&P500 Market State Charts',
  investmentSummary: 'My Investment Summary',
  moneyInvestment: 'My Money Accounts',
  getStarted: 'New Users Get Started',
  accountPosition: 'Account Position',
  maxoutUseableCash: 'The usable cash in your selected brokerage account is lower than the investment amount you enter. This will cause the trade failure in your portfolio. Do you still want to continue?',
  risklevelTooLow: 'The strategy\'s risk level is too high to subscribe. Do you want to increase your risk level?',
  risklevelTooLow1: 'The strategy has risk level of ',
  risklevelTooLow2: ' but your risk level is ',
  risklevelTooLow3: 'Do you want to change your risk level to subscribe?',
};
